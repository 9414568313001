// variables
$mobile-screen-max-width: 900px;
$gray: #444;

// rules
html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    min-height: 100%;
}

body {
    height: 100vh;
}

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
}

h1,
h2,
h3,
h4,
h5 {
    text-transform: uppercase !important;
    margin-top: 1em;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 32px;
}
